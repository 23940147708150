/**
 * Created by jgolebiewski on 21.03.2016.
 */

(function () {
    messages.controller('MessagesController', messagesController);

    messagesController.$inject = ['$scope', 'messagesService', '$anchorScroll', 'usersService', 'devicesService', 'activitiesService', 'MessagesTemplatesService', '$timeout', '$stateParams', 'localStorageService'];

    function messagesController ($scope, messagesService, $anchorScroll, usersService, devicesService, activitiesService, MessagesTemplatesService, $timeout, $stateParams, localStorageService) {
        var vm = this;
        vm.fired = false;
        $scope.newMessageForm = {
            show: false
        };

        $scope.showTime = false;

        $scope.summerNoteOptions.height = 100;
        $scope.usersList = [];
        $scope.messages = [];
        $scope.filters = {};
        $scope.loadingUsers = false;
        $scope.loadingMessages = false;
        $scope.sendingMessage = false;
        $scope.activeUser = null;
        $scope.disabledLoadUsersButton = false;

        $scope.search = {
            field: 'user',
            type: ''
        };
        if (typeof $stateParams.type !== 'undefined') {
            $scope.search.type = $stateParams.type;
        }

        $scope.newAnswer = {
            sender: $scope.loggedUser,
            text: null
        };

        $scope.pageSize = 10;
        $scope.currentPage = 1;
        $scope.total = 0;


        $scope.searching = false;

        $scope.users = [];

        $scope.data = {
            receivers: [],
            sender: $scope.loggedUser
        };

        $scope.data.group = '';
        delete $scope.data.chemical;
        delete $scope.data.category_one;
        delete $scope.data.category_two;
        $scope.data.chemicalType = '';
        $scope.data.status = '';
        $scope.data.year = '';
        $scope.data.date = {
            startDate: null,
            endDate: null
        };

        $scope.groupNames = {
        	single: '',
        	multiple: '',
        	all: 'Wszyscy',
        	group_no_products: 'Operatorzy, którzy nie mają założonych kart',
        	group_no_operations: 'Operatorzy, którzy mają założone karty bez dodanych czynności',
        	group_added_substance: 'Operatorzy, którzy dodali daną substancję do urządzenia lub systemu',
        	group_recovered_substance: 'Operatorzy, którzy odzyskali daną substancję do urządzenia lub systemu',
        	group_have_emitted: 'Operatorzy, którzy mieli emisję z urządzenia',
        	group_contain_the_substance: 'Operatorzy, których urządzenia lub systemy zawierają daną substancję',
        	group_type: 'Operatorzy danego rodzaju urządzenia lub systemu',
        	group_type_containing_the_substance: 'Operatorzy danego rodzaju urządzenia lub systemu zawierających daną substancję',
        	group_tightness_control: 'Operatorzy, którzy za określony przedział czasu muszą przeprowadzić kontrolę szczelności',
        	register: 'Dotyczace rejestracji',
        	change_admin: 'Dotyczące zmiany administratora',
        };

        $scope.groups = [
        	{ code: '', name: 'Wyszukaj odbiorców' },
        	{ code: 'all', name: $scope.groupNames.all },
        	{ code: 'group_no_products', name: $scope.groupNames.group_no_products },
        	{ code: 'group_no_operations', name: $scope.groupNames.group_no_operations },
        	{ code: 'group_added_substance', name: $scope.groupNames.group_added_substance },
        	{ code: 'group_recovered_substance', name: $scope.groupNames.group_recovered_substance },
        	{ code: 'group_have_emitted', name: $scope.groupNames.group_have_emitted },
        	{ code: 'group_contain_the_substance', name: $scope.groupNames.group_contain_the_substance },
        	{ code: 'group_type', name: $scope.groupNames.group_type },
        	{ code: 'group_type_containing_the_substance', name: $scope.groupNames.group_type_containing_the_substance },
        	{ code: 'group_tightness_control', name: $scope.groupNames.group_tightness_control },
        ];

        $scope.filterGroups = [
			{ code: '', name: 'Wszystkie' },
			{ code: 'register', name: $scope.groupNames.register },
    		{ code: 'change_admin', name: $scope.groupNames.change_admin },
        ];

        $scope.types = [
        	{ id: 1, name: 'Urządzenia' },
        	{ id: 2, name: 'Systemy Ochrony Przeciwpożarowej' },
        ];

        $scope.chemicalTypes = ['SZWO', 'FGC'];

        $scope.statuses = [
        	//{ id: '', name: 'Wszystkie' },
        	{ id: 0, name: 'Otwarte' },
        	{ id: 1, name: 'Zamknięte' },
        ];

        $scope.years = getYears();

        $scope.dateRangePickerOptions = {
    		autoUpdateInput: false,
            opens: 'left',
            startDate: new Date(),
            endDate: new Date(),
            locale: {
                'format': 'DD/MM/YYYY',
                'separator': ' - ',
                'applyLabel': 'Wybierz',
                'cancelLabel': 'Anuluj',
                'fromLabel': 'Od',
                'toLabel': 'Do',
                'customRangeLabel': 'Zdefiniuj'
            },
        };

        $scope.type = 1;

        vm.totalUsersList = 0;

        if ($scope.filters.limit == undefined) {
            $scope.filters.limit = 10;
        }

        if ($scope.filters.page == undefined) {
            $scope.filters.page = 1;
        }


        $scope.getMessagesForUser = getMessagesForUser;
        $scope.sendNewAnswer = sendNewAnswer;
        $scope.pageChanged = pageChanged;
        $scope.loadNextUsers = loadNextUsers;
        $scope.searchUsers = searchUsers;
        $scope.refresh = refresh;
        $scope.refreshMessages = refreshMessages;

        $scope.cancelForm = cancelForm;
        $scope.searchUser = searchUser;
        $scope.send = send;



        $scope.$watch('search.field', function (newValue, oldValue) {
            if (newValue != oldValue && $scope.search.value != undefined && $scope.search.value != ''){
                vm.fired = false;
                searchUsers();
            }
        });

        searchUsers();


        /**
         * Pobieranie listy korespondencyjnej
         * @param filters
         * @param firstLoad
         */
        function getUsersList(filters, firstLoad) {
            $scope.loadingUsers = true;

            if (firstLoad != undefined && firstLoad == true) {
                $scope.usersList = [];
            }

            messagesService.getSendersList(filters).then(function (data){
                if (data != false) {
                    $scope.loadingUsers = false;

                    if ($scope.usersList.length == 0) {
                        $scope.usersList = data.data;
                    } else {
                        $scope.usersList = $scope.usersList.concat(data.data);
                    }


                    vm.totalUsersList = data.total;


                    if (vm.totalUsersList ==  $scope.usersList.length) {
                        $scope.disabledLoadUsersButton = true;
                    }

                    if (firstLoad != undefined && firstLoad == true) {

                        $scope.activeUser = data.data[0];
                        getMessagesForUser($scope.activeUser, true);
                    }
                    vm.fired = false;
                }
            });
        }

        function loadNextUsers() {
            $scope.filters.page += 1;
            getUsersList($scope.filters, false);
        }


        /**
         * Filtrowanie listy nadawcow/odbiorcow
         */
        function searchUsers() {

            $scope.filters.page = 1;
            angular.extend($scope.filters, $scope.search);

            if ( vm.fired == false) {
                vm.fired = true;
                getUsersList($scope.filters, true);
            }

        }

        /**
         * pobieranie listy wiadomosci dla wybranego uzytkownika
         * @param user
         * @param clearMessages
         * @returns {boolean}
         */
        function getMessagesForUser(user, clearMessages) {

            if (user == undefined) {
                $scope.messages = [];
                return false;
            }

            var queryParams = {
                page: $scope.currentPage,
                limit:  $scope.pageSize
            };


            if ($scope.loggedUser.role.name == $scope.userRoles.su) {
                queryParams.user = user.id;

                if (user.type) {
                	queryParams.type = user.type;
                	queryParams.receiver = queryParams.user;
                	delete queryParams.user;
                }
                else if ($scope.search.type) {
                	queryParams.type = $scope.search.type;
                }
            }


            $scope.loadingMessages = true;
            $scope.activeUser = user;


            if (clearMessages == undefined || clearMessages == true) {
                $scope.messages = [];
            }


            messagesService.getMessages(queryParams).then(function (data) {
                if (data != false) {
                    $scope.messages = data.data;
                    $scope.loadingMessages = false;
                    $scope.total = data.total;
                    $anchorScroll();
                    localStorageService.set('totalMessages', null);
                }
            })
        }

        /**
         * wyslanie odpowiedzi w watku
         */
        function sendNewAnswer() {
            $scope.sendingMessage = true;
            $scope.newAnswer.receivers = [$scope.activeUser];
            messagesService.sendMessage($scope.newAnswer).then(function(data){
                $scope.sendingMessage = false;
                $scope.showTime = false;
                if (data != false) {
                    $scope.newAnswer.id = data.id;
                    $scope.newAnswer.date = data.date.date;

                    $scope.messages.unshift($scope.newAnswer);
                    $scope.newAnswer = {
                        sender: $scope.loggedUser,
                        text: null
                    };
                }
            });
        }

        function pageChanged(newPage) {
            $scope.currentPage = newPage;
            getMessagesForUser($scope.activeUser);
        }

        function refresh() {
            getUsersList($scope.filters, true);
        }

        function refreshMessages() {
            getMessagesForUser($scope.activeUser, false);
        }


        function cancelForm() {
            $scope.data.receivers = [];
            $scope.data.group = '';
            $scope.data.text = '';
            delete $scope.data.chemical;
            delete $scope.data.category_one;
            delete $scope.data.category_two;
            $scope.data.chemicalType = '';
            $scope.data.status = '';
            $scope.data.year = '';
            $scope.data.template = null;
            $scope.data.date = {
                startDate: null,
                endDate: null
            };

            $scope.newMessageForm = {
                show: false
            };
        }

        /**
         * wyszukiwanie odbiorcow wiadomosci przy tworzeniu nowej wiadomosci
         * @param $search
         * @returns {boolean}
         */
        function searchUser($search) {

            if ($search == undefined || $search == '') {
                return false;
            }

            var data = {
                // filters: [{field: 'users.surname','search': $search}]
                //'filters[0][field]': 'users.surname',
            	'filters[0][field]': 'extName',
                'filters[0][search]': $search,
                limit:100
            };
            $scope.searching = true;
            usersService.searchUser(data).then(function (data) {
                if (data != null) {
                    $scope.users = data;
                    $scope.searching = false;
                }
            })
        }

        /**
         * wyslanie nowej wiadomosci
         */
        function send() {
        	$scope.sending = true;

            messagesService.sendMessage($scope.data).then(function (data){
                if (data != null) {
                    cancelForm();
                    getUsersList($scope.filters, true);
                }
                $scope.sending = false;
            });
        }

        $scope.chemicals = [];
        $scope.chemicalsLoading = false;
        $scope.searchChemicals = function(searchTxt) {
            if (searchTxt.length == 0) {
                return false;
            }

            $scope.chemicalsLoading = true;
            devicesService.getChemicals(searchTxt).then(function (response) {
                if (response.success == true) {
                    $scope.chemicals = response.data;
                    $scope.chemicalsLoading = false;
                }
            });
        };

        $scope.deviceCategories = [];
        $scope.getDeviceCategoriesOne = getDeviceCategoriesOne;
        $scope.checkCategoriesTwo = checkCategoriesTwo;

        $scope.getDeviceCategoriesOne($scope.data.chemical || { type: 'GROUP' });
        //$scope.checkCategoriesTwo($scope.data.category_one);

        function getDeviceCategoriesOne(selectedItem) {
            if (selectedItem == undefined) {
                $scope.deviceCategories = [];
                delete $scope.data.category_one;
                delete $scope.data.category_two;
                return;
            }

            if ($scope.data.type !== undefined) {
            	$scope.type = $scope.data.type;
            }

            $scope.catsLoading = true;

            devicesService.getCategories(selectedItem.type, $scope.type).then(function (response) {
                $scope.catsLoading = false;
                if (response.success == true) {
                    $scope.deviceCategories = response.data;
                }
            });
        }

		function checkCategoriesTwo($selectedItem) {
			if (!isNaN($selectedItem.id)) {
				$selectedItem.id = parseInt($selectedItem.id);
			}
            if (devicesService.checkCategoriesTwo($selectedItem) == true) {
            	delete $scope.data.category_two;
                $scope.unSelectable = true;
            } else {
                $scope.unSelectable = false;
            }
        }

		function getYears() {
            var years = [];
            var currentYear = new Date().getFullYear();
            for (var i = currentYear; i >= 2016; i--) {
                years.push(i.toString());
            }

            return years;
        }

        $scope.setType = function(type) {
			$scope.getDeviceCategoriesOne({ type: 'GROUP' });

			delete $scope.data.category_one;
	        delete $scope.data.category_two;
        };

        $scope.reset = function() {
        	delete $scope.data.chemical;
		    delete $scope.data.category_one;
		    delete $scope.data.category_two;
		    $scope.data.chemicalType = '';
		    $scope.data.status = '';
		    $scope.data.year = '';
		    if ($scope.data.group && ($scope.data.group == 'group_added_substance' || $scope.data.group == 'group_recovered_substance' || $scope.data.group == 'group_have_emitted')) {
		    	$scope.data.status = 0;
        		$scope.data.year = new Date().getFullYear();
        	}
		    $scope.data.date = {
	            startDate: null,
	            endDate: null
	        };
        };

        $scope.parameters = function(parameters) {
        	return Object.values(JSON.parse(parameters)).filter(function(val) {
        		return val !== '' && val !== null;
            }).join(', ');
        };

        $scope.templates = [];
        $scope.templatesLoading = false;
        $scope.searchTemplates = function (searchTxt) {
            $scope.templatesLoading = true;
            MessagesTemplatesService.getTemplates(searchTxt, undefined, true).then(function (response) {
                if (response.success == true) {
                    $scope.templates = response.data;
                    $scope.templatesLoading = false;
                }
            });
        };
        $scope.searchTemplates('');

        $scope.setBodyTemplate = function (selectedItem) {
            $scope.data.text = selectedItem.body;
        };
    }

})();