(function () {
  'use strict';

  angular.module('app.export')
    .controller('ExportController', ExportController);

  ExportController.$inject = [
    '$scope',
    'ExportService',
	'devicesService',
    'provincesService',
	/*
    'reportsService',
    */
    'companyService',
    'chemicalAddedTypesService',
    '$filter',
    '$window'
  ];

  function ExportController(
    $scope,
    ExportService,
    devicesService,    
	provincesService,
	companyService,
	chemicalAddedTypesService,
    $filter,
    $window
  ) {
    var defaultCriteria = {
      group_by: null,
	  cardType: 1,
	  cardState: 0
    };

	$scope.colFilter = {};

	$scope.typeNames = {
    	no_products: 'Spis operatorów, którzy nie mają założonych kart',
    	no_operations: 'Spis operatorów, którzy mają założone karty bez dodanych czynności',
    	added_substance: 'Spis operatorów, którzy dodali daną substancję',
    	recovered_substance: 'Spis operatorów, którzy odzyskali daną substancję',
    	have_emitted: 'Spis operatorów, którzy mieli emisję z urządzenia',
    	have_devices: 'Spis operatorów z listą wszystkich urządzeń i systemów',
    	have_substance: 'Spis operatorów z łączną ilością substancji oraz emisji w urządzeniach',
    };

    $scope.reportTypes = [
    	{ code: 'liczbaOperatorow', name: 'Liczba operatorów' },
    	{ code: 'spisOperatorow', name: 'Spis operatorów' },
    	{ code: 'liczbaUrzadzen', name: 'Liczba urządzeń' },
    	{ code: 'iloscSubstancji', name: 'Ilość substancji zawartych w urządzeniach' },
    	{ code: 'iloscSubstancjiOdzyskanych', name: 'Ilość substancji odzyskanej' },
    	{ code: 'iloscSubstancjiDodanych', name: 'Ilość substancji dodanej' },
    	{ code: 'no_products', name: $scope.typeNames.no_products },
    	{ code: 'no_operations', name: $scope.typeNames.no_operations },
    	{ code: 'added_substance', name: $scope.typeNames.added_substance },
    	{ code: 'recovered_substance', name: $scope.typeNames.recovered_substance },
    	{ code: 'have_emitted', name: $scope.typeNames.have_emitted },
    	{ code: 'have_devices', name: $scope.typeNames.have_devices },
    	{ code: 'have_substance', name: $scope.typeNames.have_substance },
    ];
    if ($scope.loggedUser.role.name != $scope.userRoles.su) {
    	$scope.reportTypes.splice(6);
    	$scope.reportTypes.splice(0, 2);
    }

    $scope.types = [
    	{ id: 1, name: 'Karty urządzeń' },
    	{ id: 2, name: 'Karty Systemów Ochrony Przeciwpożarowej' },
    ];

    $scope.statuses = [
    	{ id: 0, name: 'Tylko otwarte' },
    	{ id: 1, name: 'Tylko zamknięte' },
    ];

    $scope.chemicalAddedTypes = chemicalAddedTypesService.getList();
	
	$scope.getProvinceList = getProvinceList;
    /*$    
    $scope.getSectionFieldList = getSectionFieldList;
    $scope.initForm = initForm;
    $scope.onSelectCity = clearCompanyList;
    $scope.onSelectProvince = clearCompanyList;        
    $scope.searchCities = searchCities;
    */
    $scope.searchCompanies = searchCompanies;
	//$scope.onSelectReportType = onSelectReportType;
	$scope.cancelForm = cancelForm;
	$scope.searchChemicals = searchChemicals;
	$scope.save = save;

    //$scope.cityList = [];
    $scope.chemicalList = [];
    $scope.saving = false;
    // TODO: Trochę to chyba bez sensu, bo to obejście już gotowej kontrolki - brak uniwersalnej?
    $scope.tableParams = {filter: filterDateRange};

    initialize();
    //onSubstanceChange();

    //////////////////////////////////////////////
    function cancelForm() {
      //$scope.exportForm.$setPristine(); //exportForm unknown, ale warto to uruchomić
      //$scope.exportForm.$setUntouched();
      setDefaultCriteria();
    }
/*
    function clearCompanyList(value, type) {
      if (value && $scope.reportCriteria.company) {
        var res = [];
        switch (type) {
          case 'province':
            res = $filter('filter')($scope.companyList, {province: value});
            break;
          case 'city':
            res = $filter('filter')($scope.companyList, {city: value.city});
            break;
        }
        if (!res.length) {
          delete $scope.reportCriteria.company;
        }
      }
      $scope.companyList = [];
    }
*/
    function filterDateRange(range) {
      $scope.reportCriteria.submittedDate = range.range;
    }

    function getProvinceList() {
	  return $scope.provinceList;
	  /*
      if (!$scope.reportCriteria.city) {
        return $scope.provinceList;
      } else {
        // tylko województwo z wybranego już miasta
        var result = [];
        angular.forEach($scope.cityList, function(city) {
          if (city.city === $scope.reportCriteria.city) {
            result.push(city.province);
          }
        });
        return result;
      }*/
    }

    function getSectionFieldList(section) {
      return $filter('filter')($scope.sectionFieldsList, {sections_id: section});
    }

    function initForm(form) {
      $scope.exportForm = form;
    }

	
    function initialize() {
      setDefaultCriteria();
	  /*
      ExportService.getSections().then(function(sections) {
        $scope.sectionList = sections;
      });
      ExportService.getSectionFields().then(function(fields) {
        $scope.sectionFieldsList = fields;
      });*/
	  
      $scope.provinceList = provincesService.getProvincesWithoutNames();
      /*ExportService.getProductTypes().then(function(types) {
        $scope.productTypeList = types;
      });*/
	  	
    }
	
	/*
    function onSelectReportType() {
      delete $scope.reportCriteria.sectionField;
    }
*/
    function onSubstanceChange() {
      $scope.$watch('reportCriteria.substance', function() {
        if ($scope.reportCriteria.chemical) {
          delete $scope.reportCriteria.chemical;
        }
        $scope.chemicalList = [];
      });
    }

    function save() {
      //if ($scope.exportForm.$valid) {
        var reportFilename = 'raport.csv';
		$scope.reportCriteria.range=$scope.colFilter;
        ExportService.generateReport(angular.copy($scope.reportCriteria)).then(function(hash) {
        	if (hash) {
        		$window.location.href = '/export/' + hash + '/' + reportFilename;
        	}
        });
      //}
    }

    function searchCities(searchTxt) {
      if (searchTxt.length == 0) {
        return false;
      }

      var searchObj = {
        city: searchTxt,
        province: $scope.reportCriteria.province,
      };

      $scope.citiesLoading = true;

      companyService.getCities(searchObj).then(function (response) {
        $scope.cityList = response.data;
      }).finally(function() {
        $scope.citiesLoading = false;
      });
    }

    function searchChemicals(searchTxt) {
      if (searchTxt.length == 0) {
        return false;
      }

      $scope.chemicalsLoading = true;

      //var type = $scope.reportCriteria.substance === 'szwo' ? 'SZWO' : 'F-GAZ';

      devicesService.getChemicals(searchTxt).then(function (response) {
		if (response.success == true) {
                    $scope.chemicalList = response.data;                    
                    $scope.chemicalsLoading = false;
        }        
      }).finally(function() {
        $scope.chemicalsLoading = false;
      });
    }

    $scope.deviceCategories = [];
    $scope.getDeviceCategoriesOne = getDeviceCategoriesOne;
    $scope.checkCategoriesTwo = checkCategoriesTwo;

    $scope.getDeviceCategoriesOne($scope.reportCriteria.chemical || { type: 'GROUP' });
    //$scope.checkCategoriesTwo($scope.reportCriteria.productType);

    function getDeviceCategoriesOne(selectedItem) {
        if (selectedItem == undefined) {
            $scope.deviceCategories = [];
            delete $scope.reportCriteria.productType;
            delete $scope.reportCriteria.productsubType;
            return;
        }

        $scope.catsLoading = true;

        devicesService.getCategories(selectedItem.type, $scope.reportCriteria.cardType).then(function (response) {
            $scope.catsLoading = false;
            if (response.success == true) {
                $scope.deviceCategories = response.data;
            }
        });
    }

	function checkCategoriesTwo($selectedItem) {
        if (devicesService.checkCategoriesTwo($selectedItem) == true) {
        	delete $scope.reportCriteria.productsubType;
            $scope.unSelectable = true;
        } else {
            $scope.unSelectable = false;
        }
    }

	$scope.setType = function(type) {
		$scope.getDeviceCategoriesOne({ type: 'GROUP' });

		delete $scope.reportCriteria.productType;
        delete $scope.reportCriteria.productsubType;
    };

    function searchCompanies(searchTxt) {
      if (searchTxt.length == 0) {
        return false;
      }

      var searchObj = {
        q: searchTxt,
        /*
        city: $scope.reportCriteria.city,
        province: $scope.reportCriteria.province
        */
      };

      $scope.companyLoading = true;

      companyService.getCompanies(searchObj).then(function (response) {
        $scope.companyList = response.data;
      }).finally(function() {
        $scope.companyLoading = false;
      });
    }

    function setDefaultCriteria() {
      $scope.reportCriteria = angular.copy(defaultCriteria);
    }
  }
})();